import React, { useState,useEffect } from 'react';
import './Registration-Form.css';
// import miragelogo from '../../assets/Core/Mirage Logo - Transparent.png';
import acelogo from '../../assets/Core/Ace Logo - Transparent.png';
import hotdroplogo from '../../assets/Core/HotDrop Logo - Transparent.png';
import PlayerInfo from './PlayerInfo';
import Summary from './summary';
import axios from '../../api/axios';

const REGISTRATION_URL = "/registration"

const Registration = () => {
  const [currentScreen, setCurrentScreen] = useState(1);

  const [playersData, setPlayersData] = useState([]);
  const [playerNumber,setPlayerNumber] = useState(null);
  const [success,setSuccess] = useState(false);
  const [err,setErr] = useState(null)

  const [player1data,setPlayer1Data] = useState(null);
  const [player2data,setPlayer2Data] = useState(null);
  const [player3data,setPlayer3Data] = useState(null);
  const [player4data,setPlayer4Data] = useState(null);
  const [player5data,setPlayer5Data] = useState(null);
  const [player6data,setPlayer6Data] = useState(null);

  const [applicantfirstname,setApplicantfirstname] = useState('');
  const [applicantlastname,setApplicantlastname] = useState('');
  const [applicantcontact,setApplicantcontact] = useState('');
  const [applicantemail,setApplicantemail] = useState('');
  const [applicantcollege,setApplicantCollege] = useState('');
  const [teamname,setTeamName] = useState('');
  const [teamleader,setTeamleader] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');


  const saveData = (data) => {
    if (playersData.length === 0){
      setPlayersData([data]);
    }
    else{
      let newPlayerData = [
        ...playersData.slice(0,playerNumber-1),data,
        ...playersData.slice(playerNumber)

      ]

      setPlayersData(newPlayerData);
    }
  };

    useEffect(() => {
      
      playersData.forEach((player, index) => {
        switch (index) {
          case 0:
            setPlayer1Data(player);
            break;
          case 1:
            setPlayer2Data(player);
            break;
          case 2:
            setPlayer3Data(player);
            break;
          case 3:
            setPlayer4Data(player);
            break;
          case 4:
            setPlayer5Data(player);
            break;
          case 5:
            setPlayer6Data(player);
            break;
          default:
            break;
        }
      });
    }, [playersData]);
    
    
    const data = {
      name: applicantfirstname + " " + applicantlastname,
      email: applicantemail,
      contact: applicantcontact,
      college: applicantcollege,
  
      teamname: teamname,
      teamleader: teamleader,
      game: selectedEvent,
  
      name1: player1data?.firstName + " " + player1data?.lastName,
      email1: player1data?.email,
      contact1: player1data?.contactno,
      ign1: player1data?.gameID,
      rollno1:player1data?.rollno,
      pfp1: player1data?.pfp,
      IdCard1: player1data?.collegeID,
  
      name2: player2data?.firstName + " " + player2data?.lastName,
      email2: player2data?.email,
      contact2: player2data?.contactno,
      ign2: player2data?.gameID,
      rollno2:player1data?.rollno,
      pfp2: player2data?.pfp,
      IdCard2: player2data?.collegeID,
  
      name3: player3data?.firstName + " " + player3data?.lastName,
      email3: player3data?.email,
      contact3: player3data?.contactno,
      ign3: player3data?.gameID,
      rollno3:player1data?.rollno,
      pfp3: player3data?.pfp,
      IdCard3: player3data?.collegeID,
  
      name4: player4data?.firstName + " " + player4data?.lastName,
      email4: player4data?.email,
      contact4: player4data?.contactno,
      ign4: player4data?.gameID,
      rollno4:player1data?.rollno,
      pfp4: player4data?.pfp,
      IdCard4: player4data?.collegeID,
  
      name5: player5data?.firstName + " " + player5data?.lastName,
      email5: player5data?.email,
      contact5: player5data?.contactno,
      ign5: player5data?.gameID,
      rollno5:player1data?.rollno,
      pfp5: player5data?.pfp,
      IdCard5: player5data?.collegeID,
  
      name6: player6data?.firstName + " " + player6data?.lastName,
      email6: player6data?.email,
      contact6: player6data?.contactno,
      ign6: player6data?.gameID,
      rollno6:player1data?.rollno,
      pfp6: player6data?.pfp,
      IdCard6: player6data?.collegeID,
  };
  
    


    const handleSubmit = async () => {
      
      try {
          const response = await axios.post(REGISTRATION_URL,
              JSON.stringify(data),
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
          );
          console.log("success")
          console.log(JSON.stringify(response));
          setSuccess(true);
      } catch (err) {
        if (!err?.response) {
            setErr('No Server Response');
        } else {
            setErr('Registration Failed')
        }
    }
  }


  const handleFirstNameChange = (event) => {
    setApplicantfirstname(event.target.value)
  }
  const handleLastNameChange = (event) => {
    setApplicantlastname(event.target.value)
  }
  const handleApplicantEmailChange = (event) => {
    setApplicantemail(event.target.value)
  }
  const handleApplicantContactChange = (event) => {
    setApplicantcontact(event.target.value)
  }
  const handleApplicantCollegeChange = (event) => {
    setApplicantCollege(event.target.value)
  }
  const handleteamnamechange = (event) => {
    setTeamName(event.target.value)
  }
  const handleteamleaderchange = (event) => {
    setTeamleader(event.target.value)
  }
  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    
  };


  
  const handleNext = () => {
    const screenToPlayerNumber = {
      3: 1,
      4: 2,
      5: 3,
      6: 4,
      7: 5,
      8: 6
    };

    const playerNumber = screenToPlayerNumber[currentScreen+1];
    if (playerNumber !== undefined) {
        setPlayerNumber(playerNumber);
    }
    setCurrentScreen(currentScreen + 1);
  };

  const handleBack = () => {
    const screenToPlayerNumber = {
      3: 1,
      4: 2,
      5: 3,
      6: 4,
      7: 5,
      8: 6
    };

    const playerNumber = screenToPlayerNumber[currentScreen-1];
    if (playerNumber !== undefined) {
        setPlayerNumber(playerNumber);
    }
    
    
    console.log(`Screen Going Back ${currentScreen-1}`)
    setCurrentScreen(currentScreen -1);
  };

  const renderScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return (
          <div>
            <div>
              <div className="registration-form">
                <h3 className="registration-regform gamingevent">Gaming Event</h3>
                <hr />
                <h1 className="registration-regform">Registration Form</h1>
                <div className="main-container">
                  <div className="registration-inner-regform" >
                    <input className="registration-input" onChange={handleFirstNameChange} name="fname" type="text" placeholder="First name" required />
                    <input className="registration-input" onChange={handleLastNameChange} name="lname" type="text" placeholder="Last name" required />
                    <input className="registration-input" onChange={handleApplicantEmailChange} name="email" type="email" placeholder="E-mail" required />
                    <input className="registration-input" onChange={handleApplicantContactChange} name="tel" type="tel" placeholder="Phone No." required />
                    
                  </div>
                  <div className="registration-course">
                      <select name="registration-course" id="course" onChange={handleApplicantCollegeChange}>
                        <option className="registration-course-option" value="0">Select College</option>
                        <option className="registration-course-option" value="1">CGC Jhanjeri</option>
                        <option className="registration-course-option" value="2">CGC Landran</option>
                        <option className="registration-course-option" value="3">CU</option>
                        <option className="registration-course-option" value="4">LPU</option>
                        <option className="registration-course-option" value="5">DAV</option>
                        <option className="registration-course-option" value="6">PU</option>
                        <option className="registration-course-option" value="7">PEC</option>
                        <option className="registration-course-option" value="8">THAPAR</option>
                        <option className="registration-course-option" value="9">AMITY</option>
                        <option className="registration-course-option" value="10">RAYAT BAHARA</option>
                      </select>
                  </div>
                </div>
                <hr />
                <div className="registration-5">
                  <input className="registration-submit-btn" type="button" value="Next" onClick={handleNext} />
                </div>
                <h3 className="registration-team-head">All The best Guys !!!</h3>
              </div>
              
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="registration-screen-2-team-form">
              <h1 className="registration-team-head registration-event-head"> Select Event</h1>
              <div action="">
                <input className="registration-input registration-teamname" onChange={handleteamnamechange} name="team_name" type="text" placeholder="Team Name" />
                <br />
                <input className="registration-input registration-teamname" onChange={handleteamleaderchange} type="text" placeholder="Team Leader Name" />
                <div className="registration-radio-logo">
                </div>
                <div className="registration-event-radio">
                  <div className="registration-event-select">
                    <label htmlFor="event-ace"><img className='registration-logo' src={acelogo} alt="" /></label>
                    <input className='registration-event-selec-radio-btn' type="radio" id="event-ace" name="event" value="Ace" onChange={handleEventChange} />
                  </div>
                  <div className="event-select">
                    <label htmlFor="event-hotdrop"><img className='registration-logo' src={hotdroplogo} alt="" /></label>
                    <input className='registration-event-selec-radio-btn' type="radio" id="event-hotdrop" name="event" value="HotDrop" onChange={handleEventChange} />
                  </div>
                </div>
              </div>
              <div className="registration-radio-flexbtn">
                <input className="registration-radio-submit-btn " onClick={handleBack} type="button" value="Back" />
                <input className="registration-radio-submit-btn " onClick={handleNext} type="button" value="Next" />
              </div>
            </div>
          </div>
        );
      case 3:
        if (selectedEvent === "Ace") {
          
          return (
          <PlayerInfo playerNumber={1} key={playerNumber} playerdata = {player1data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>
          
          
          );
        } else if (selectedEvent === "HotDrop") {
          return <PlayerInfo playerNumber={1} key={playerNumber} playerdata = {player1data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        }
        else{
          setCurrentScreen(2)
        }
        break;
      case 4:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={2} key={playerNumber} playerdata = {player2data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        } else if (selectedEvent === "HotDrop") {
          return <PlayerInfo playerNumber={2} key={playerNumber} playerdata = {player2data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        }
        break;
      case 5:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={3} key={playerNumber} playerdata = {player3data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        } else if (selectedEvent === "HotDrop") {
          return <PlayerInfo playerNumber={3} key={playerNumber} playerdata = {player3data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        }
        break;
      case 6:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={4} key={playerNumber} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        } else if (selectedEvent === "HotDrop") {
          return <PlayerInfo playerNumber={4} key={playerNumber} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        }
        break;
      case 7:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={5} key={playerNumber} handleBack={handleBack} handleNext={handleNext} saveData={saveData} err={err}/>;
        } else if (selectedEvent === "HotDrop") {
          return <PlayerInfo playerNumber={5} key={playerNumber} handleBack={handleBack}  saveData={saveData} handleSubmit={handleSubmit} err={err}/>;
        }
        break;
      case 8:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={6} key={playerNumber} playerdata = {player6data} handleBack={handleBack} handleSubmit={handleSubmit} saveData={saveData} err={err}/>;
        }
        break;
      
      default:
      return null;
    }
  };
  




return (
    <>
    <div className="registration-main">
          {!success ? (
            <>
              {renderScreen(currentScreen)}
              <div className="registration-rules">
                    <hr />
                    <h4>RULES AND INSTRUCTUONS</h4>
                    <ol>
                      <li>You have to bring your own system in which the game is installed.</li>
                      <li>Lorem ipsum dolor sit amet consectetur lorem adipisicing elit. Vitae, dolores!</li>
                      <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias repellendus deleniti officia cum!</li>
                      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                      <li>Lorem ipsum dolor sit amet consectetur.</li>
                      <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni consequatur sequi reprehenderit?</li>
                      <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque, molestiae!</li>
                      <li>Lorem ipsum dolor sit amet consectetur.</li>
                    </ol>
              </div>
            </>
              ) : (
                <Summary/>
              )}
          
          
          
        </div>
      
    </>
  );
  
};

export default Registration;
