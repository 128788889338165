import React from 'react';
import { Home, Sponsors,About, Members, Events,Schedule, Contact, Signin,Signup,Registration, Ace,Unauthorised,Pagenotfound,Profile,ForgotPass, HotDrop} from './Pages';
import Layout from './components/Layout';
import './App.css';
import { Routes, Route} from 'react-router-dom';
import RequireAuth from './hooks/RequireAuth';
import PersistLogin from './Pages/Sign-in/PersistLogin';

export const ROLES = {
  'User':10,
  'Admin':10000,
  'Editor':5000
}

const App = () => {
  return (
    <>
    <Routes>
      <Route className = "Layout" path="/" element={<Layout />}>
      
        {/* public routes*/}
        
        <Route path='/signin' element={<Signin />} />
        <Route path='/forgotpass' element={<ForgotPass />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/unauthorised' element={<Unauthorised />} />
        <Route path='/pagenotfound' element={<Pagenotfound />} />
        <Route path='/sponsors' element={<Sponsors />} />
        <Route path='/register' element={<Registration />} />

        <Route element={<PersistLogin/>}>

          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          {/* <Route path='/' element={<Members />} /> */}
          <Route path='/schedule' element={<Schedule />} /> 
          <Route path='/contact' element={<Contact />} /> 
          <Route path='/Events' element={<Events />} />
          <Route path='/ace' element={<Ace />} />
          <Route path='/hotdrop' element={<HotDrop />} />
          <Route path='/profile' element={<Profile />} />
          
          
          
        </Route>
        
        {/* err route*/}
        <Route path='*' element={<Pagenotfound />} />
        
          

      </Route>
      
    </Routes>
    

  </>
  
  )
}

export default App
