import React from 'react';
import './Hotdrop.css';
import hotdropimg from '../../assets/Core/HotDrop Logo - Transparent.png'
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

const Home = () => {


  


  return (
    <div className='hotdrop-main'>
      <div className='hotdrop-cards-row'>
        <div className='hotdrop-horizontal-card'>
          <div className='hotdrop-horizontal-card-left'>
            <img className="hotdrop-horizontal-card-userimage" alt="" src={hotdropimg}/>
          </div>
          <div className='hotdrop-horizontal-card-right'>
            <p>Click here to Register</p>
            <Link to='/register'><button className="hotdrop-event-button" type='button'>Register</button></Link>
          </div>
        </div>
      </div>
      <div className="hotdrop-bottom">
        <div className='hotdrop-info'>
          <p>BGMI is a Indian version of game PUBG mobile which is exclusively available in India. We here at CGC are offering you a platform "HOTDROP" to show your gaming skills. Embark on your BGMI gaming journey in our point based tournament. Aiming for your direct victory in our thrilling competition.

Teams will accumulate points on every kill, each victory, and their final rank in every match. These metrics will determine their standings on the ranking table.

Inspired by the international stages, our tournament format aims to replicate the thrilling experience for all the participants. Be a Game changer, not just a gamer!!!</p>
        </div>
        


      </div>

    </div >


  )
}

export default Home












