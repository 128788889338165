  import React from 'react';
import './Ace.css';
import aceimg from '../../assets/Core/Ace Logo - Transparent.png'
import "react-multi-carousel/lib/styles.css";

import { Link } from 'react-router-dom';

const Ace = () => {





  return (
    <div className='ace-main'>
      <div className='ace-cards-row'>
        <div className='ace-horizontal-card'>
          <div className='ace-horizontal-card-left'>
            <img className="ace-horizontal-card-userimage" alt="" src={aceimg}/>
          </div>
          <div className='ace-horizontal-card-right'>
            <p>Click here to Register</p>
            <Link to='/register'><button className="ace-event-button" type='button'>Register</button></Link>
          </div>
        </div>
      </div>
      <div className="ace-bottom">
        <div className='ace-info'>
          <p>
          With  a diverse and action-packed environment Valorant is an amusement designed with a 
          strategic gameplay which is a  captivating  ambiance for  first person shooter[FPS] enthusiasts.   
          
          Valorant is  going to be a roller coaster ride ,featuring multiple bombsites ,chokepoints, weapons and 
          agents ,each having signature abilities of their own.

          Experience the pinnacle of this game in our cutting-edge 5v5 tournament.
          The teams enter a fierce single-elimination format,where each match is a do-or-die scenario.
          The winning teams from each of the rounds advance directly to the semifinals.
          The victorious squads proceed to the finals ,adhering to standard valorant rules .
          This is going to be an intense 5v5 showdown  revealing the  ultimate champion.

          The thrill of victory and the drive to improve  will keep  the players engaged and motivated 
          to disclose their artistry in this showdown.

          Overall,the gaming environment is  going to be competitive and strategically rich , providing
          players with an engaging and rewarding experience.
          </p>
        </div>
        


      </div>

    </div >


  )
}

export default Ace












