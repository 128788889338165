import {React} from 'react';
import './Home.css';
import comingsoon from '../../assets/1.gif';
import { useNavigate } from 'react-router-dom';




const targetDate = new Date('2024-03-28T00:00:00').getTime();

// Renderer function



const Home = () => {
  const navigate = useNavigate();



  
  

  return (
    
    <div className='home-main'>
        <img src={comingsoon} alt="comingsoon video" />
        
    </div>
  );
}

export default Home;
