import React, { useState } from 'react';
import './Registration-Form.css';

const Summary = () => {
  const [playerDetails, setPlayerDetails] = useState({
    player1: false,
    player2: false,
    player3: false,
    player4: false,
    player5: false,
    player6: false
  });

  const togglePlayerDetails = (player) => {
    setPlayerDetails(prevState => ({
      ...prevState,
      [player]: !prevState[player]
    }));
  };

  return (
    <div className="main-summary-box">
      <div className="summary-contact-details"> 
        {[1, 2, 3, 4, 5, 6].map((playerNum) => (
          <div className={`summary-share player${playerNum}`} key={`player${playerNum}`}>
            <h1 className='summary-contact-heading' onClick={() => togglePlayerDetails(`player${playerNum}`)}>
              Player-{playerNum}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;▼
              <hr />
            </h1>
            <div className={`summary-content ${playerDetails[`player${playerNum}`] ? 'show' : ''}`}>
              <div className="summary-input1">
                <input type="text" placeholder=' Name' />
                <input type="text" placeholder='Enter Mail'/>
              </div>
              <div className="summary-input2">
                <input type="text" placeholder=' Number' />
                <input type="text" placeholder='Game ID' />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="summary-booking">
        <div className="summary-booking-content">
          <h1 className='summary-booking-heading'>BOOKING SUMMARY</h1><br /><br />
          {/* Add your booking summary content here */}
          <div className="summary-booking-venus">
    <h1>VENUS</h1>
   <p>English -2D</p>
   <div className="summary-booking-city">
   <h1>CITY WALK</h1>
   <p>02</p  >
   </div>
   <div className="summary-booking-date">
     <p>10 sep TUE,11:00</p>
     <p>TICKETS</p>
   </div>
   <div className="summary-booking-tickets">
     <h1>TICKETS PRICE</h1>
     <p>$150</p>
   </div>
   <div className="summary-booking-combo">
     <h1>COMBO</h1>
     <p>$57</p>
   </div>
   <p>2 Nachos Combo</p>
   <h1 >FOOD $ BEVARAGE</h1>
   <div className="summary-booking-price">
     <p>PRICES</p>
     <span>$208</span>
   </div>
   <div className="summary-booking-vat">
     <p>VAT</p>
     <span>4</span>
   </div>
   <div className="summary-booking-amount">
     <h1>AMOUNT </h1>
     <h2>$567</h2>
   </div>
   <div className="summary-booking-btn"> <button>PROCEED</button></div>
          
        </div>
      </div>
    </div>
    </div>  );
}

export default Summary;
