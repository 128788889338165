import React, { useState, useEffect } from 'react';
import './Registration-Form.css';
import { storage } from '../../hooks/firebase';
import upload from '../../assets/icons8/icons8-upload.png';
import player_img from '../../assets/BGs/facex-2.jpg';


const PlayerInfo = ({ playerNumber,playerdata, handleBack, handleNext,saveData,handleSubmit,err}) => {
  const [pfp, setpfp] = useState(null);
  const [collegeimage, setCollegeImage] = useState(null);
  const [pfpUrl, setpfpUrl] = useState(null);
  const [collegeimageUrl, setCollegeImageUrl] = useState(null);
  const [pfpLoading, setpfpLoading] = useState(false);
  const [collegeImageLoading, setCollegeImageLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gameID, setGameID] = useState('');
  const [contactno, setContactno] = useState('');
  const [rollno, setRollno] = useState('');
  const [email, setEmail] = useState('');
  const [errmsg,setErrmsg] = useState('');
  

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleGameIDChange = (e) => {
    setGameID(e.target.value);
  };
  const handleContactnoChange = (e) => {
    setContactno(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlerollNoChange = (e) => {
    setRollno(e.target.value);
  };



  useEffect(() => {
    if (err) {
      setErrmsg(err)
    }
  }, [err]);

  useEffect(() => {
    if (pfp) {
      handlepfpUpload();
    }
  }, [pfp]);
  useEffect(() => {
    if (collegeimage) {
      handleCollegeIdUpload();
    }
  }, [collegeimage]);


  const handlepfpChange = (e) => {
    if (e.target.files[0]) {
      setpfp(e.target.files[0]);
    }
  };
  const handleCollegeImageChange = (e) => {
    if (e.target.files[0]) {
      setCollegeImage(e.target.files[0]);
    }
  };
  const handleCollegeIdUpload = () => {
    setCollegeImageLoading(true);
    const uploadTask = storage.ref(`images/${collegeimage.name}`).put(collegeimage);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Track upload progress if needed
      },
      (error) => {
        console.error('Error uploading image:', error);

      },
      () => {
        storage
          .ref('images')
          .child(collegeimage.name)
          .getDownloadURL()
          .then((url) => {
            setCollegeImageUrl(url); // Save the image URL
            setCollegeImageLoading(false);
          });
      }
    );
  };
  const handlepfpUpload = () => {
    setpfpLoading(true);
    const uploadTask = storage.ref(`images/pfp/${pfp.name}`).put(pfp);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        storage
          .ref('images/pfp')
          .child(pfp.name)
          .getDownloadURL()
          .then((url) => {
            setpfpUrl(url); // Save the image URL
            setpfpLoading(false);
          });
      }
    );
  };


  const handleSubmitdata = () => {
    // Collect data
    const formData = {
      firstName: playerdata?.firstName? playerdata.firstName : firstName,
      lastName: playerdata?.lastName ? playerdata.lastName : lastName,
      gameID: playerdata?.gameID ? playerdata.gameID : gameID,
      contactno:playerdata?.contactno ? playerdata.contactno : contactno,
      collegeID:playerdata?.collegeID ? playerdata.collegeID : collegeimageUrl,
      rollno:playerdata?.rollno ? playerdata.rollno : rollno,
      pfp:playerdata?.pfp ? playerdata.pfp : pfpUrl,
      email:playerdata?.email ? playerdata.email : email,
    };

    saveData(formData);
  };


  const handlenextandsubmit = async () => {
    await handleSubmitdata();
    try{
      await handleNext();
      
    }
    catch{}
    try{
      await handleSubmit();
    }
    catch{}
    
    
  }

  return (
    <div className="registration-screen-3">
      <h1 className="registration-team-head event-head"> Player-{playerNumber}</h1>
      <hr />
      <form onSubmit={handlenextandsubmit}>
        <div className='registration-pfp-container'>
          {pfpLoading ? (
              <div>
                <button className="" type="button" disabled/>
                <button className="registration-players-upload-button" type="button" disabled>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 101" className="inline w-4 h-4 mr-3 text-white animate-bounce" role="status" aria-hidden="true">
                  <circle fill="#34D399" r="45" cy="50" cx="50"></circle>
                </svg>
                Uploading...
                </button>
              </div>
            ) : pfpUrl? (
              <div>
                <img className = "registration-pfp-image" src={pfpUrl} alt="Gov ID" />
              </div>
            ): playerdata?.pfp? (
              <div>
                <img className = "registration-id-image" src={playerdata.pfp} alt="Gov ID" />
              </div>
            ): (
              <>
                <label htmlFor="pfpfile">
                <img className = "registration-pfp-image" src={player_img} alt='icon'/></label>
                
                <input className="registration-pfp-upload" type="file" onChange={handlepfpChange} id="pfpfile" name="myfile" hidden />
              </>
            )}
        </div>
        <div className="registration-inner-regform">
          <input className="registration-input" id={`fname-${playerNumber}`} value={playerdata?.firstName ? playerdata.firstName : firstName} onChange={handleFirstNameChange} type="text" placeholder=" First Name" required/>
          <input className="registration-input" id={`lname-${playerNumber}`} value={playerdata?.lastName ? playerdata.lastName : lastName} onChange={handleLastNameChange} type="text" placeholder=" Last Name" required/>
          <input className="registration-input" id={`email-${playerNumber}`} value={playerdata?.email ? playerdata.email : email} onChange={handleEmailChange} type="text" placeholder=" Email" required/>
          <input className="registration-input" id={`ugameid-${playerNumber}`} type="text" value={playerdata?.gameID ? playerdata.gameID : gameID} onChange={handleGameIDChange} placeholder=" Game ID " required/>
          <input className="registration-input" id={`tel-${playerNumber}`} type="tel" value={playerdata?.contactno ? playerdata.contactno : contactno} onChange={handleContactnoChange} placeholder="Whatsapp No." required/>
          <input className="registration-input" id={`rollno-${playerNumber}`} type="tel" value={playerdata?.rollno ? playerdata.rollno : rollno} onChange={handlerollNoChange} placeholder="College/University Roll No." required/>
        </div>
        <hr />
        <div className="registration-screen-file">
        
          {collegeImageLoading ? (
            <div>
              <button className="registration-players-upload-button" type="button" disabled>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 101" className="inline w-4 h-4 mr-3 text-white animate-bounce" role="status" aria-hidden="true">
                  <circle fill="#34D399" r="45" cy="50" cx="50"></circle>
                </svg>
                Uploading...
              </button>
            </div>
          ) : collegeimageUrl ? (
            <div>
              <img className="registration-id-image" src={collegeimageUrl} alt="College ID" />
            </div>
          ) : playerdata?.collegeID? (
            <div>
              <img className = "registration-id-image" src={playerdata.collegeID} alt="College ID" />
            </div>
          ) : (
            <>
              <label htmlFor="registration-collegeid">
                <img className="registration-id-image" src={upload} alt='icon' />Upload College ID</label>

              <input className="registration-fileupload" type="file" onChange={handleCollegeImageChange} id="registration-collegeid" name="myfile" hidden />
            </>
          )}

        </div>
        <p>{errmsg}</p>
        <div className="registration-flexbtn">
          <input className="registration-submit-btn save-btn" onClick={handleBack} type="button" value="Back" />
          {/* <input className="registration-submit-btn save-btn" disabled={pfpLoading || collegeImageLoading} onClick={handlenextandsubmit} type="button" value="Next " /> */}
          <input className="registration-submit-btn save-btn" disabled={pfpLoading || collegeImageLoading} type="submit" value="Next " />

        </div>
      </form>
    </div>
  );
};

export default PlayerInfo;
