import React from 'react';
import './About.css';
import Carousel from "react-multi-carousel";
import { productData, responsive } from "./About-Data";



const About = () => {
  
  
  
  return (
    <div className='about2-main'>

      <div className='about2-core'>
        <div className='about2-box'>
          <div className="about2-college-slideshow">
            <div className="about2-Carousel">
              
            </div>
          </div>
          <div className='about2-text-box'>
            <p className='about2-college-text'>
            Step into the vibrant world of Chandigarh group of Colleges, Jhanjeri. It is recognised 
            internationally as a leading college/university in the areas of technical, natural sciences, 
            management and law, also renowned for business-oriented approach. Here at CGC, we focus on 
            sustainability and an open minded and innovative study environment. We provide students with a 
            platform to pursue their interests and passions. We offer a space to students to come together, so 
            that they can engage more in co-curricular activities with keeping a good impact in academics 
            too.
            Embrace the challenges, seize the opportunities,make the most of every moment at CGC!!!
            </p>
          </div>
        </div>
        
        
        

      </div>

    </div>
  )
}

export default About





