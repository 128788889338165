

import img1 from '../../assets/icons8/img11.svg';
import img2 from '../../assets/icons8/img22.svg';
import img3 from '../../assets/icons8/img33.svg';
import img4 from '../../assets/icons8/img44.svg';
import img5 from '../../assets/icons8/img55.svg';
import img6 from '../../assets/icons8/img66.svg';
import img7 from '../../assets/icons8/img77.svg';
import img8 from '../../assets/icons8/img88.svg';
import img9 from '../../assets/icons8/img99.svg';
import img10 from '../../assets/icons8/img10.svg';


const sponsorImages = [
  {
    imageurl: img1,
    onClickLink: 'https://example.com/img1'
  },
  {
    imageurl: img2,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img3,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img4,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img5,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img6,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img7,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img8,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img9,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img10,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img8,
    onClickLink: 'https://example.com/img2'
  },
  {
    imageurl: img9,
    onClickLink: 'https://example.com/img2'
  },

];


export default sponsorImages;
