// Members.js

import React from 'react';
import './Members.css';
import { skillsData ,socialMediaData} from './membersData';
import membersData from './membersData';
import homebg from '../../assets/BGs/home-coffee.jpg'
import signature from '../../assets/icons8/icons8-signature-50.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Members = () => {
    return (
        <div className='courses-core'>
            <div className='courses-header-text'>
                <h1>Welcome To UpSkill@IndiTech</h1>
                <br/>
                <h2>Where You Learn to Up Your Game</h2>
            </div>
            <div className='courses-head-container'>
                <div className='courses-head'>
                    <h1 className='courses-head-title'>Our Courses</h1>
                </div>
                <div className='courses-data'>
                {membersData.map((courselist, index) => (
                    
                    <div className="courses-row-container" key={index}>
                        <h1>{courselist.course}</h1>
                        <div className='courses-row'>
                            {courselist.courses.map((member, index) => (
                                <div className='courses-card' key={index}>
                                    <img className='courses-card-top' src={member.thumbnail} alt='' />
                                    <div className='courses-card-bottom'>
                                        <h3 className='courses-card-bottom-text'>{member.course_name}</h3>
                                        <h4 className='courses-card-bottom-text'>By - {member.course_author}</h4>
                                    </div>
                                    <div className='courses-button-container'>
                                        {/* <a className="courses-button-container" href={member.button_link}> */}
                                        <button className="courses-button">
                                            <p className="courses-button-text">Learn More</p>
                                        </button>
                                        {/* </a> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                </div>
            </div>
        </div>
    );
}

export default Members;
